

























































import { Component, Vue } from "vue-property-decorator";
import { IHandlerCreate } from "@/interfaces";
import { dispatchCreateHandler, dispatchGetEvent } from "@/store/main/actions";
import { readEvent } from '@/store/main/getters';

@Component
export default class Create extends Vue {
  public valid = false;
  public loading = true;

  public handler: IHandlerCreate = {
    name: "",
    type: "",
    params_1: "",
    event_id: 0,
  };

  public reset() {
    this.handler = <IHandlerCreate>{};
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }
  
  get event(){
    return readEvent(this.$store);
  }

  public async mounted() {
    await dispatchGetEvent(this.$store, Number(this.$router.currentRoute.params.id));
    this.loading = false;
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      this.handler.event_id = Number(this.$router.currentRoute.params.id);
      await dispatchCreateHandler(this.$store, this.handler);
      this.$router.back();
    }
  }
}
